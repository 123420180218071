/**
 * Custom Mixins
 *
 */

/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/

$xs: ( max:  767px );
$sm: ( min:  768px );
$md: ( min:  992px );
$lg: ( min: 1200px );
$sm-only: ( min: map-get($sm, min), max: map-get($md, min) - 1 );
$md-only: ( min: map-get($md, min), max: map-get($lg, min) - 1 );

@mixin breakpoint($map) {
  $query: "";
  @if map-has-key($map, min) { $query: append($query, "(min-width: #{map-get($map, min)})") }
  @if map-has-key($map, min) and map-has-key($map, max) { $query: append($query, "and") }
  @if map-has-key($map, min) and map-has-key($map, min-height) { $query: append($query, "and") }
  @if map-has-key($map, max) { $query: append($query, "(max-width: #{map-get($map, max)})") }
  @if map-has-key($map, min-height) { $query: append($query, "(min-height: #{map-get($map, min-height)})") }
  @if map-has-key($map, min-height) and map-has-key($map, max-height) { $query: append($query, "and") }
  @if map-has-key($map, max-height) { $query: append($query, "(max-height: #{map-get($map, max-height)})") }
  @media screen and #{$query} { @content; }
}

/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/

@mixin container-wrap() {
  @include container-fixed();

  @include breakpoint($sm) {
    width: $container-sm;
  }
  @include breakpoint($md) {
    width: $container-md;
  }
  @include breakpoint($lg) {
    width: $container-lg;
  }
}

/* =Fontello Icon Font
----------------------------------------------------------*/

@mixin fontello() {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  //width: 1em;
  //margin-right: .2em;

  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  //margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

/* =Dead Center within parent
----------------------------------------------------------*/

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  @include translate(-50%, -50%);
}

/* =Vertically Center
----------------------------------------------------------*/

@mixin v-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%); /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
  -ms-transform: translateY(-50%); /* IE 9 */
  transform: translateY(-50%); /* IE 10, Fx 16+, Op 12.1+ */
}
